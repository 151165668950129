import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M3783 1973 c-358 -3 -413 -5 -413 -18 0 -13 75 -15 595 -15 588 0
595 0 595 20 0 19 -6 20 -182 18 -101 -2 -369 -4 -595 -5z"/>
<path d="M533 1954 c-7 -3 -9 -12 -6 -20 4 -12 59 -14 347 -14 518 0 836 10
836 27 0 11 -102 13 -582 12 -321 0 -588 -3 -595 -5z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
